body {
  background-image: url('https://images5.alphacoders.com/876/876590.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
  margin-right: 500px;
  padding: 0;
  font-family: sans-serif;
}

main {
  width: 85vw;
  min-height: 80vh;
  max-width: 1024px;
  margin: 35px auto;
  background: #fff;
  padding: 15px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.grid-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.page-view {
  min-height: 40vh;
}

.grid-view div.card.ui {
  margin: 1.25rem;
}

header h1 {
  text-align: center;
}


.character-list, .card-container, .location-list, .episode-list {
  background-color: #262C3A;
  border-radius: 10px;
}

.header-style {
  background-color: #B5EDFC;
}

.item {
  background-color: #262C3A;
}